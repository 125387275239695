@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.6;
  color: #2d2f31;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}